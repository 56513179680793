import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SupportService } from "../../service/SupportService";
import { useForm, Controller } from "react-hook-form";
import { SubjectTypeService } from "../../service/SubjectTypeService";
import { PriorityService } from "../../service/PriorityService";
import { Dropdown } from "primereact/dropdown";
import { ObjectToArray } from "../../components/ObjectToArrayConvert";
import { useTranslation } from "../../components/hooks/Translate";

export const SupportDialogs = ({ visible, hide }) => {
    let page=window.location.href.split("#")[1].replace("/","");
    const t = useTranslation();
    let emptyData = {
        subjectTypeId: "",
        priorityId: "",
        subject: "",
        email: "",
        phoneNumber: "",
        description: "",
        fromPage: page,
    };
    
    const [datas, setDatas] = useState(null);
    const [subjectTypes, setSubjectTypes] = useState(null);
    const [prioritys, setPrioritys] = useState(null);
    const [data, setData] = useState(emptyData);

    const toast = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ emptyData });

    const supportService = new SupportService();
    const subjectTypeService = new SubjectTypeService();
    const priorityService = new PriorityService();

    useEffect(() => {
        supportService.getList().then((data) => setDatas(data));
        subjectTypeService.getList().then((data) => setSubjectTypes(ObjectToArray(data, "name", "id")));
        priorityService.getList().then((data) => setPrioritys(ObjectToArray(data, "name", "id")));
    }, []);


    const onSubmit = (val) => {
        Object.assign(data, val);
        supportService.create(data).then((data) => {
            if (data === "success") {
                
                toast.current.show({ severity: "success", summary: t.translate("Successful"), detail: t.translate("Created"), life: 3000 });
                supportService.getList().then((data) => setDatas(data));
                hide()
            } else {
                toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
            }
        });

        reset();
        setData(emptyData);
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog style={{ width: "450px" }} header="Support Request"  modal className="p-fluid" visible={visible} onHide={hide}>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="subjectTypeId" control={control} rules={{ required: "SubjectTypeId is required." }} render={({ field, fieldState }) => <Dropdown options={subjectTypes} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                            <label htmlFor="subjectTypeId" className={classNames({ "p-error": !!errors.subjectTypeId })}>
                                {t.translate("SubjectTypeId")}
                            </label>
                        </span>
                        {getFormErrorMessage("subjectTypeId")}
                    </div>{" "}
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="priorityId" control={control} rules={{ required: "PriorityId is required." }} render={({ field, fieldState }) => <Dropdown options={prioritys} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                            <label htmlFor="priorityId" className={classNames({ "p-error": !!errors.priorityId })}>
                                {t.translate("PriorityId")}
                            </label>
                        </span>
                        {getFormErrorMessage("priorityId")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="subject" control={control} rules={{ required: "Subject is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="subject" className={classNames({ "p-error": !!errors.subject })}>
                                {t.translate("Subject")}
                            </label>
                        </span>
                        {getFormErrorMessage("subject")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="email" control={control} rules={{ required: "Email is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="email" className={classNames({ "p-error": !!errors.email })}>
                                {t.translate("Email")}
                            </label>
                        </span>
                        {getFormErrorMessage("email")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="phoneNumber" control={control} rules={{ required: "PhoneNumber is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="phoneNumber" className={classNames({ "p-error": !!errors.phoneNumber })}>
                                {t.translate("PhoneNumber")}
                            </label>
                        </span>
                        {getFormErrorMessage("phoneNumber")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="description" control={control} rules={{ required: "Description is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="description" className={classNames({ "p-error": !!errors.description })}>
                                {t.translate("Description")}
                            </label>
                        </span>
                        {getFormErrorMessage("description")}
                    </div>                   
                    <div className="grid">
                        <div className="col-6">
                            <Button type="submit" label={t.translate("Save")} />
                        </div>
                        <div className="col-6">
                            <Button label={t.translate("Cancel")} className="p-button-danger" onClick={hide} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
