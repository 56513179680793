import { UserGroup } from "../models/userGroup";
import api from "./Api";
import GenericService from "./GenericService";
const entitySlug = "User-Groups";
export class UserGroupService extends GenericService<UserGroup> {
    constructor() {
        super(entitySlug);
    }

    getUsersInGroupByGroupId = async (id: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/groups/" + id + "/users").then((res) => {
            return res.data;
        });
    };

    getUserGroupByUserId = async (id: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/users/" + id).then((res) => {
            return res.data;
        });
    };

    updateByGroupId = async (body: Omit<Partial<any>, "id" | "created" | "updated">): Promise<any> => {
        return await api
            .put<any>(entitySlug + "/groups/", body)
            .then(() => {
                return "success";
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
