import api from "./Api";

export default class GenericService<T> {
    constructor(entitySlug: string) {
        this.entitySlug = entitySlug;
    }

    private entitySlug: string;

    getbyid = async (id: string): Promise<T> => {
        return await api.get<T>(this.entitySlug + "/" + id).then(async (res) => {
            return await res.data;
        });
    };

    getList = async () => {
        return await api.get<T[]>(this.entitySlug).then((res) => {
            return res.data;
        });
    };
    
    create = async (body: Omit<T, "id" | "created" | "updated">): Promise<T> => {
        return await api
            .post<T>(this.entitySlug, body)
            .then(() => {
                return "success";
            })
            .catch((err) => {
                return err.response.data;
            });
    };

    update = async (body: Omit<Partial<T>, "id" | "created" | "updated">): Promise<T> => {
        return await api
            .put<T>(this.entitySlug, body)
            .then(() => {
                return "success";
            })
            .catch((err) => {
                return err.response.data;
            });
    };

    delete = async (id: number): Promise<void> => {
        return await api
            .delete<T>(this.entitySlug + "/" + id)
            .then(() => {
                return "success";
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
