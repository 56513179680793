import { useAuthContext } from "../../Context";
import { AuthContext } from "../../Context";

export const Visible = ({ login, roles, children }) => {
    const user = useAuthContext();
    const loginMatch = login && user.login === login;
    return (
        <AuthContext.Consumer>
            {({ isAuthenticated, isBusy, claims }) => {
                if (!isBusy) {
                    let returnContent = "";
                    if (isAuthenticated && claims.length > 0) {
                        const rolesMatch = roles && roles.some((r) => claims.includes(r));
                        return Boolean(loginMatch || rolesMatch) && children;
                    }
                    return returnContent;
                }
            }}
        </AuthContext.Consumer>
    );
};
