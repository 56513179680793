import { Log } from "../models/log";
import apiGraphQL from "./ApiGraphQL";
import GenericService from "./GenericService";

export class LogService extends GenericService<Log> {
    constructor() {
        super("Logs");
    }
    getGraphQLList = async (query: any): Promise<any> => {
        return await apiGraphQL
            .post("", {
                query,
            })
            .then((res) => {
                return res.data.data
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
