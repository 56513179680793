import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { exportPdf, exportExcel } from "../components/Export";
import { SupportService } from "../service/SupportService";
import { useForm, Controller } from "react-hook-form";
import { SubjectTypeService } from "../service/SubjectTypeService";
import { PriorityService } from "../service/PriorityService";

import { Dropdown } from "primereact/dropdown";
import { ObjectToArray } from "../components/ObjectToArrayConvert";
import { Calendar } from "primereact/calendar";
import { Visible } from "../components/hooks/Visible";
import { useTranslation } from "../components/hooks/Translate";
import { Checkbox } from "primereact/checkbox";
import { UserDtoService } from "../service/UserDtoService";

const Supports = () => {
    const t = useTranslation();
    let emptyData = {
        subjectTypeId: "",
        priorityId: "",
        subject: "",
        email: "",
        phoneNumber: "",
        description: "",
        fromPage: "",
        requestDate: "",
        requestUser: "",
        solvedUser: "",
        solvedDate: "",
        isSolved: "",
        solvedDescription: "",
    };

    const [datas, setDatas] = useState(null);
    const [subjectTypes, setSubjectTypes] = useState(null);
    const [prioritys, setPrioritys] = useState(null);
    const [users, setUsers] = useState(null);
    const [solvedUsers, setSolvedUsers] = useState(null);

    const [filteredData, setFilteredData] = useState(null);
    const [dataDialog, setDataDialog] = useState(false);
    const [deleteDatasDialog, setDeleteDatasDialog] = useState(false);
    const [data, setData] = useState(emptyData);
    const [selectedDatas, setSelectedDatas] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({ emptyData });

    const supportService = new SupportService();
    const subjectTypeService = new SubjectTypeService();
    const priorityService = new PriorityService();
    const userService = new UserDtoService();

    useEffect(() => {
        supportService.getList().then((data) => setDatas(data));
        subjectTypeService.getList().then((data) => setSubjectTypes(ObjectToArray(data, "name", "id")));
        priorityService.getList().then((data) => setPrioritys(ObjectToArray(data, "name", "id")));
        userService.getList().then((data) => setUsers(ObjectToArray(data, "fullName", "userId")));
        userService.getList().then((data) => setSolvedUsers(ObjectToArray(data, "fullName", "userId")));
        initFilters();
    },[]);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            subjectTypeId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            priorityId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            subject: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phoneNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            description: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            fromPage: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            requestDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            requestUser: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            solvedUser: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            solvedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            isSolved: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilter("");
    };

    

    const hideDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setDataDialog(false);
    };

    const hideDeleteDatasDialog = () => {
        setDeleteDatasDialog(false);
    };

    const onSubmit = (val) => {
        Object.assign(data, val);
        if (data.id) {
            supportService.update(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Successful"), detail: t.translate("Updated"), life: 3000 });
                    supportService.getList().then((data) => setDatas(data));
                    setSelectedDatas(null);
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        } else {
            supportService.create(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Successful"), detail: t.translate("Created"), life: 3000 });
                    supportService.getList().then((data) => setDatas(data));
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        }

        setDataDialog(false);
        reset();
        setData(emptyData);
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };
    const editData = (data) => {
        let p = data[0];

        setValue("subjectTypeId", p.subjectTypeId);
        setValue("priorityId", p.priorityId);
        setValue("subject", p.subject);
        setValue("email", p.email);
        setValue("phoneNumber", p.phoneNumber);
        setValue("description", p.description);
        setValue("fromPage", p.fromPage);
        setValue("requestDate", p.requestDate);
        setValue("requestUser", p.requestUser);
        setValue("solvedUser", p.solvedUser);
        setValue("solvedDate", p.solvedDate);
        setValue("isSolved", p.isSolved);
        setValue("solvedDescription", p.solvedDescription);
        setData({ ...p });
        setDataDialog(true);
    };

    //#region  Export
    const columns = [
        { field: "subjectTypeId", header: t.translate("SubjectTypeId") },
        { field: "priorityId", header: t.translate("PriorityId") },
        { field: "subject", header: t.translate("Subject") },
        { field: "email", header: t.translate("Email") },
        { field: "phoneNumber", header: t.translate("PhoneNumber") },
        { field: "description", header: t.translate("Description") },
        { field: "fromPage", header: t.translate("FromPage") },
        { field: "requestDate", header: t.translate("RequestDate") },
        { field: "requestUser", header: t.translate("RequestUser") },
        { field: "solvedUser", header: t.translate("SolvedUser") },
        { field: "solvedDate", header: t.translate("SolvedDate") },
        { field: "isSolved", header: t.translate("IsSolved") },
        { field: "solvedDescription", header: t.translate("SolvedDescription") },
    ];

    const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportPdff = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportPdf(exportColumns, exportData, "Data");
    };

    const exportExcell = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportExcel(exportData, "Data");
    };
    //#endregion


    const deleteSelectedDatas = () => {
        let _datas = datas.filter((val) => !selectedDatas.includes(val));

        selectedDatas.forEach((element) => {
            supportService.delete(element.id).then((data) => {
                if (data !== "success") {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        });
        setDatas(_datas);
        setDeleteDatasDialog(false);
        setSelectedDatas(null);
        toast.current.show({ severity: "success", summary: t.translate("Successful"), detail: `${_datas.leght} items deleted`, life: 3000 });
    };

    const editSelectedData = () => {
        editData(selectedDatas);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">                    
                    <Visible roles={["UpdateSupportCommand"]}>
                        <Button icon="pi pi-pencil" className="p-button-success" onClick={editSelectedData} tooltip={t.translate("Update")} tooltipOptions={{ position: "top" }} disabled={!selectedDatas || !selectedDatas.length || selectedDatas.length > 1} />
                    </Visible>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={exportCSV} className="mr-2" data-pr-tooltip="CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcell} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdff} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-datas-center">
            <h5 className="m-0">{t.translate("Supports")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t.translate("Search...")} />
            </span>
        </div>
    );

    const deleteDialogFooter = (
        <>
            <Button label={t.translate("No")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDatasDialog} />
            <Button label={t.translate("Yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDatas} />
        </>
    );
    const subjectTypeBodyTemplate = (rowData) => {
        if (rowData !== undefined) {
            let obj = subjectTypes?.find((o) => o.value === rowData.subjectTypeId);
            return <p>{obj?.label}</p>;
        }
    };
    const priorityBodyTemplate = (rowData) => {
        if (rowData !== undefined) {
            let obj = prioritys?.find((o) => o.value === rowData.priorityId);
            return <p>{obj?.label}</p>;
        }
    };
    const requestUserBodyTemplate = (rowData) => {
        if (rowData !== undefined) {
            let obj = users?.find((o) => o.value === rowData.requestUser);
            return <p>{obj?.label}</p>;
        }
    };
    const solvedUserBodyTemplate = (rowData) => {
        if (rowData !== undefined) {
            let obj = solvedUsers?.find((o) => o.value === rowData.solvedUser);
            return <p>{obj?.label}</p>;
        }
    };
    const requestDateBodyTemplate = (rowData) => {
        return <p>{new Date(rowData.requestDate).toLocaleDateString()}</p>;
    };
    const solvedDateBodyTemplate = (rowData) => {
        return <p>{new Date(rowData.solvedDate).toLocaleDateString()}</p>;
    };
    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', {'true-icon pi-check-circle': rowData.isSolved, 'false-icon pi-times-circle': !rowData.isSolved})}></i>;
    }
    return (
        <Visible roles={["GetSupportQuery"]}>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={datas}
                            selection={selectedDatas}
                            filters={filters}
                            onSelectionChange={(e) => setSelectedDatas(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
                            globalFilter={globalFilter}
                            emptyMessage="No data found."
                            header={header}
                            responsiveLayout="scroll"
                            onValueChange={(filteredData) => setFilteredData(filteredData)}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                            <Column field="isSolved" header={t.translate("IsSolved")} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedBodyTemplate}  />
                            <Column field="subjectTypeId" header={t.translate("subjectType ")} filter sortable body={subjectTypeBodyTemplate}></Column>
                            <Column field="priorityId" header={t.translate("priority ")} filter sortable body={priorityBodyTemplate}></Column>
                            <Column field="subject" header={t.translate("Subject")} filter sortable></Column>
                            <Column field="email" header={t.translate("Email")} filter sortable></Column>
                            <Column field="phoneNumber" header={t.translate("PhoneNumber")} filter sortable></Column>
                            <Column field="description" header={t.translate("Description")} filter sortable></Column>
                            <Column field="fromPage" header={t.translate("FromPage")} filter sortable></Column>
                            <Column field="requestDate" header={t.translate("requestDate")} filter sortable body={requestDateBodyTemplate}></Column>
                            <Column field="requestUser" header={t.translate("RequestUser")} filter sortable body={requestUserBodyTemplate}></Column>
                            <Column field="solvedUser" header={t.translate("SolvedUser")} filter sortable body={solvedUserBodyTemplate}></Column>
                            <Column field="solvedDate" header={t.translate("solvedDate")} filter sortable body={solvedDateBodyTemplate}></Column>                            
                            <Column field="solvedDescription" header={t.translate("SolvedDescription")} filter sortable></Column>
                           
               
                        </DataTable>

                        <Dialog visible={dataDialog} style={{ width: "450px" }} header="Support" modal className="p-fluid" onHide={hideDialog}>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="subjectTypeId" control={control} rules={{ required: "SubjectTypeId is required." }} render={({ field, fieldState }) => <Dropdown options={subjectTypes} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                        <label htmlFor="subjectTypeId" className={classNames({ "p-error": !!errors.subjectTypeId })}>
                                            {t.translate("SubjectTypeId")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("subjectTypeId")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="priorityId" control={control} rules={{ required: "PriorityId is required." }} render={({ field, fieldState }) => <Dropdown options={prioritys} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                        <label htmlFor="priorityId" className={classNames({ "p-error": !!errors.priorityId })}>
                                            {t.translate("PriorityId")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("priorityId")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="subject" control={control} rules={{ required: "Subject is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="subject" className={classNames({ "p-error": !!errors.subject })}>
                                            {t.translate("Subject")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("subject")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="email" control={control} rules={{ required: "Email is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="email" className={classNames({ "p-error": !!errors.email })}>
                                            {t.translate("Email")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("email")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="phoneNumber" control={control} rules={{ required: "PhoneNumber is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="phoneNumber" className={classNames({ "p-error": !!errors.phoneNumber })}>
                                            {t.translate("PhoneNumber")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("phoneNumber")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="description" control={control} rules={{ required: "Description is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="description" className={classNames({ "p-error": !!errors.description })}>
                                            {t.translate("Description")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("description")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="fromPage" control={control} rules={{ required: "FromPage is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="fromPage" className={classNames({ "p-error": !!errors.fromPage })}>
                                            {t.translate("FromPage")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("fromPage")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller
                                            name="requestDate"
                                            control={control}
                                            rules={{ required: "requestDate is required." }}
                                            render={({ field, fieldState }) => 
                                            <Calendar id={field.name} dateFormat="dd.mm.yy" value={new Date(data.requestDate)} className={classNames({ "p-invalid": fieldState.invalid })}  />
                                        }
                                        />
                                        <label htmlFor="requestDate" className={classNames({ "p-error": !!errors.requestDate })}>
                                            {t.translate("RequestDate")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("requestDate")}
                                </div>{" "}

                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="requestUser" control={control} rules={{ required: "RequestUser is required." }} render={({ field, fieldState }) => <Dropdown options={users} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                        <label htmlFor="requestUser" className={classNames({ "p-error": !!errors.requestUser })}>
                                            {t.translate("RequestUser")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("requestUser")}
                                </div>{" "}   

                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="solvedUser" control={control} rules={{ required: "SolvedUser is required." }} render={({ field, fieldState }) => <Dropdown options={solvedUsers} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                        <label htmlFor="solvedUser" className={classNames({ "p-error": !!errors.solvedUser })}>
                                            {t.translate("SolvedUser")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("solvedUser")}
                                </div>{" "}                              
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="solvedDescription" control={control} rules={{ required: "SolvedDescription is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="solvedDescription" className={classNames({ "p-error": !!errors.solvedDescription })}>
                                            {t.translate("SolvedDescription")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("solvedDescription")}
                                </div>{" "}
                                <div className="field-checkbox">
                                    <Controller name="isSolved" control={control} render={({ field, fieldState }) => <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                    <label htmlFor="isSolved" className={classNames({ "p-error": errors.isSolved })}>                                       
                                        {t.translate("IsSolved")}
                                    </label>
                                </div>{" "}
                                <div className="grid">
                                    <div className="col-6">
                                        <Button type="submit" label={t.translate("Save")} />
                                    </div>
                                    <div className="col-6">
                                        <Button label={t.translate("Cancel")} className="p-button-danger" onClick={(e) => hideDialog(e)} />
                                    </div>
                                </div>
                            </form>
                        </Dialog>

                        <Dialog visible={deleteDatasDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDatasDialog}>
                            <div className="flex align-datas-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {data && <span>{t.translate("Are you sure you want to delete the selected datas?")}</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </Visible>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Supports, comparisonFn);
