import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { TranslateService } from "../../service/TranslateService";
import { LanguageService } from "../../service/LanguageService";
import { ObjectToArray } from "../ObjectToArrayConvert";
import classNames from "classnames";
import { useTranslation } from "../hooks/Translate";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";

export const SyncTranslate = React.forwardRef(({ visible, isSuccess, hide }, ref) => {
    const t = useTranslation();
    const [languages, setlanguages] = useState();
    const languageService = new LanguageService();
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let emptyData = {
        langId: "",
    };
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ emptyData });
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    useEffect(() => {
        languageService.getlookups().then((data) => setlanguages(ObjectToArray(data, "label", "id")));
    }, []);

    const onSubmit = async (val) => {
        const translateService = new TranslateService();
        console.log(val)
        const contents = await getCodes(val.file);
        setLoading(true);
        translateService.uploadFiles(val.langId, contents).then((data) => {
            setLoading(false);
            if (data === "success") {
                toast.current.show({ severity: "success", summary: "Successful", detail: "successful", life: 3000 });
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: data, life: 3000 });
            }
        });
        hide();
        reset();
    };

    const getCodes = async (files) => {
        const contents = await readAllFiles(files);
        let content = "";
        contents.forEach((element) => {
            content = content + element;
        });
        var myRegexp = new RegExp(/t.translate\("[a-zA-Z]*/gm);
        let matches = (content.match(myRegexp) || []).map((e) => e.replace('t.translate("', ""));
        let uniqueMatches = matches.filter((v, i, a) => a.indexOf(v) === i);
        return uniqueMatches;
    };

    const readAllFiles = async (files) => {
        // Convert the FileList into an array and iterate
        let resFiles = Array.from(files).map((file) => {
            // Define a new file reader
            let reader = new FileReader();
            // Create a new promise
            return new Promise((resolve) => {
                // Resolve the promise after reading file
                reader.onload = () => resolve(reader.result);
                // Read the file as a text
                reader.readAsText(file);
            });
        });
        let res = await Promise.all(resFiles);
        return res;
    };


    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={t.translate("Sync Tranlate")} visible={visible} style={{ width: "50vw", maxWidth: "80vw" }} modal onHide={hide}>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="langId" control={control} rules={{ required: "LangId is required." }} render={({ field, fieldState }) => <Dropdown options={languages} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                            <label htmlFor="langId" className={classNames({ "p-error": !!errors.langId })}>
                                {t.translate("langId")}
                            </label>
                        </span>
                        {getFormErrorMessage("langId")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="file" control={control} rules={{ required: "file is required." }} render={({ field, fieldState }) => <input type="file" {...register("file")} multiple="multiple" accept="text/javascript" className={classNames({ "p-invalid": fieldState.invalid })} />} />
                        </span>
                        {getFormErrorMessage("file")}
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <Button type="submit" loading={loading} label={t.translate("Save")} />
                        </div>
                        <div className="col-6">
                            <Button label={t.translate("Cancel")} className="p-button-danger" onClick={hide} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    );
});
