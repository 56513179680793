import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { UserDtoService } from "../service/UserDtoService";
import { CompanyService } from "../service/CompanyService";
import { OrganizationService } from "../service/OrganizationService";
import { ObjectToArray } from "../components/ObjectToArrayConvert";
import { Toast } from "primereact/toast";
import { Visible } from "../components/hooks/Visible";
import { ChangePassword } from "../components/ChangePassword";
import { useTranslation } from "../components/hooks/Translate";
import { Toolbar } from "primereact/toolbar";
import { AuthContext } from "../Context";

const ProfilePage = ({ user }) => {
    const t = useTranslation();
    const [data, setData] = useState(null);
    const [organizations, setOrganizations] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [changePasswordDialog, setChangePasswordDialog] = useState();
    const toast = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm();
    const { userId, companyId, organizationId, fullName, email, mobilePhones, address, notes } = user;
    const disabledList = [
        'companyId','email', 'organizationId'
    ];
    const userdtoService = new UserDtoService();
    const companiesService = new CompanyService();
    const organizationService = new OrganizationService();

    useEffect(() => {
        userdtoService.getbyid(userId).then((data) => {
            setValue("userId", userId);
            setValue("companyId", companyId);
            setValue("organizationId", organizationId);
            setValue("fullName", fullName);
            setValue("email", email);
            setValue("mobilePhones", mobilePhones);
            setValue("address", address);
            setValue("notes", notes);
            setData({ ...user });
        });

        organizationService.getList().then((data) => setOrganizations(ObjectToArray(data, "organizationName", "id")));
        companiesService.getList().then((data) => setCompanies(ObjectToArray(data, "name", "id")));
    }, []);

    const hideChangePasswordDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setChangePasswordDialog(false);
    };

    const changePasswordsDialog = () => {
        setChangePasswordDialog(true);
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const onSubmit = (val) => {


        Object.keys(val).forEach(key => {
            if(disabledList.includes(key))
                delete val[key];
        });
        Object.assign(data, val);
        userdtoService.update(data).then((data) => {
            if (data === "success") {
                toast.current.show({ severity: "success", summary:  t.translate("Updated"), detail: "Created", life: 3000 });
            } else {
                toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
            }
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Visible roles={["UserChangePasswordCommand"]}>
                        <Button icon="pi pi-lock" className="p-button-secondary mr-2" onClick={() => changePasswordsDialog()} disabled={!userId} tooltip={t.translate("ChangePassword")} tooltipOptions={{ position: "top" }} />
                    </Visible>
                </div>
            </React.Fragment>
        );
    };

    const UserEditSection = () => {
        return (
            <div className="mt-5">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-4">
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="companyId" control={control} rules={{ required: "companyId is required." }} render={({ field, fieldState }) => <Dropdown options={companies} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} disabled={disabledList.includes(field.name)} />} />
                            <label htmlFor="companyId" className={classNames({ "p-error": !!errors.companyId })}>
                                Firma
                            </label>
                        </span>
                        {getFormErrorMessage("companyId")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="organizationId" control={control} rules={{ required: "OrganizationId is required." }} render={({ field, fieldState }) => <Dropdown options={organizations} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} disabled={disabledList.includes(field.name)} />} />
                            <label htmlFor="organizationId" className={classNames({ "p-error": !!errors.organizationId })}>
                                Organizasyon
                            </label>
                        </span>
                        {getFormErrorMessage("organizationId")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="fullName" control={control} rules={{ required: "FullName is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="fullName" className={classNames({ "p-error": !!errors.fullName })}>
                                FullName
                            </label>
                        </span>
                        {getFormErrorMessage("fullName")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="email" control={control} rules={{ required: "Email is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} disabled={disabledList.includes(field.name)} />} />
                            <label htmlFor="email" className={classNames({ "p-error": !!errors.email })}>
                                Email
                            </label>
                        </span>
                        {getFormErrorMessage("email")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="mobilePhones" control={control} rules={{ required: "MobilePhones is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="mobilePhones" className={classNames({ "p-error": !!errors.mobilePhones })}>
                                MobilePhones
                            </label>
                        </span>
                        {getFormErrorMessage("mobilePhones")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="address" control={control} rules={{ required: "Address is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="address" className={classNames({ "p-error": !!errors.address })}>
                                Address
                            </label>
                        </span>
                        {getFormErrorMessage("address")}
                    </div>
                    <div className="field mt-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller name="notes" control={control} rules={{ required: "Notes is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                            <label htmlFor="notes" className={classNames({ "p-error": !!errors.notes })}>
                                Notes
                            </label>
                        </span>
                        {getFormErrorMessage("notes")}
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <Button type="submit" label={t.translate("Save")} />
                        </div>
                        <div className="col-6">
                            <Button label={t.translate("Cancel")} className="p-button-danger" />
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    const RenderUserIntro = () => {
        return (
            <div className="pt-2 pb-4">

                <div className="inline-flex align-items-center py-2 px-2">
                    <span className="p-button-success">
                        {data?.status && <i className="pi pi-check-circle" style={{ fontSize: "2em", color:'#22C55E' }}></i>}
                    </span>
                    <span className="text-900 font-medium h3 pl-3">
                        {data?.fullName}
                    </span>
                </div>
                <p>{data?.email} - adminRole</p>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
            </div>
        );
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-6" left={leftToolbarTemplate}></Toolbar>
                    <RenderUserIntro />
                    <UserEditSection />
                    <ChangePassword visible={changePasswordDialog} userId={userId} hide={hideChangePasswordDialog}></ChangePassword>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

const Profile = ({ login, roles, children }) => {
    return (
        <AuthContext.Consumer>
            {({ user }) => {
                return user && <ProfilePage user={user} />;
            }}
        </AuthContext.Consumer>
    );
};

export default React.memo(Profile, comparisonFn);
