import React, { useState, useEffect } from "react";

export const useTranslation = () => {
    let currentLanguage = sessionStorage.getItem("langs");
    let translation = sessionStorage.getItem("translates");
    
    const [language, setLanguage] = useState(currentLanguage);

    useEffect(() => {
        currentLanguage = language || "tr-TR";
    }, [language]);

    if (translation) {
        translation = JSON.parse(translation);
        const languages = Object.fromEntries(Object.entries(translation).map(([key, value]) => [key, value]));
        const translate = (key) => (translation?.[key]? translation?.[key] : key);
        return { translate, setLanguage, language, languages };
    } 
};
