import api from "./Api";

class AuthService {
    async login(email: string, password: string) {
        return await api
            .post("auth/login", { email, password })
            .then((res) => {
                if (res.data.success) {
                    sessionStorage.setItem("token", JSON.stringify(res.data.data.token));
                    sessionStorage.setItem("refreshToken", JSON.stringify(res.data.data.refreshToken));
                }
                return res.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    }

    logout() {
        sessionStorage.clear();
        window.location.reload();
    }

    async refreshToken(refreshToken: string) {
        return api
            .post("auth/refreshToken", { refreshToken })
            .then((res) => {
                if (res.data.success) {
                    const { expiration, token, refreshToken } = res.data.data;
                    sessionStorage.setItem("expiration", JSON.stringify(expiration));
                    sessionStorage.setItem("token", JSON.stringify(token));
                    sessionStorage.setItem("refreshToken", JSON.stringify(refreshToken));
                }
                return true;
            })
            .catch((err) => {
                this.logout();
                return false;
            });
    }

    changePassword = async (userId: number, password: string) => {
        return await api.put<any>("auth/user-password", { userId, password }).then((res) => {
            return res.data;
        });
    };
}

export default AuthService;
