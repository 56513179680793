import axios, { AxiosRequestTransformer } from "axios";
import { setupInterceptorsTo } from "./Interceptors";

const dateTransformer = (data: any): any => {
    if (data instanceof Date) {

        const timezoneOffsetInHours = -(data.getTimezoneOffset() / 60);
        const sign = timezoneOffsetInHours >= 0 ? "+" : "-";
        const leadingZero = Math.abs(timezoneOffsetInHours) < 10 ? "0" : "";

        const correctedDate = new Date(data.getFullYear(), data.getMonth(), data.getDate(), data.getHours(), data.getMinutes(), data.getSeconds(), data.getMilliseconds());
        correctedDate.setHours(data.getHours() + timezoneOffsetInHours);
        const iso = correctedDate.toISOString().replace("Z", "");

        return iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ":00";
    }
    if (Array.isArray(data)) {
        return data.map(dateTransformer);
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]));
    }
    return data;
};

const api = setupInterceptorsTo(
    axios.create({
        baseURL:  process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
        },
        transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
    })
);

export default api;