import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { UserGroupService } from "../../service/UserGroupService";
import { GroupService } from "../../service/GroupService";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useTranslation } from "../hooks/Translate"

export const GroupUsers = ({ visible, userId, hide }) => {
    const t = useTranslation();
    const [claims, setClaims] = useState();
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const toast = useRef(null);
    const userGroupService = new UserGroupService();
    const groupService = new GroupService();

    useEffect(() => {
        if (userId?.length > 0) {
            userGroupService.getUserGroupByUserId(userId[0].userId || userId).then((data) => setSelectedUserIds(data.map((d) => d.id.toString())));
            groupService.getList().then((data) => setClaims(data?.map((d) => ({ label: d.groupName, value: d.id.toString() }))));
        }
    }, [userId]);

    const addOrUpdateUserIds = (userId) => {
       if (userId.length > 0) {
        userGroupService.update({ userId: userId[0].userId, groupId: selectedUserIds }).then((res) => {
                if (res === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Added"), detail: "Created", life: 3000 });
                    hide()
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: res, life: 3000 });
                }
            });
            
       }
    };

    const footer = (
        <div>
            <Button label={t.translate("Save")} icon="pi pi-check" onClick={() => addOrUpdateUserIds(userId)} />
            <Button label={t.translate("No")} icon="pi pi-times" onClick={hide} />
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Kullanıcı Grupları" footer={footer} visible={visible} style={{ maxWidth: "60vw" }} modal onHide={hide}>
                <MultiSelect value={selectedUserIds} options={claims} onChange={(e) => setSelectedUserIds(e.value)} optionLabel="label" placeholder="Lütfen Grup Seçiniz" filter display="chip" style={{ maxWidth: "50vw" }} maxSelectedLabels={3} />
            </Dialog>
        </div>
    );
};
