import api from "./Api";
import GenericService from "./GenericService";

const entitySlug = "Reports";
export class ReportService extends GenericService<any> {
    constructor() {
        super(entitySlug);
    }

    getDailyRoomReport = async (organizationId: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/daily-room/" + organizationId).then(async (res) => {
            return await res.data;
        });
    };

    getRoomCountReport = async (organizationId: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/room-count/" + organizationId).then(async (res) => {
            return await res.data;
        });
    };
}
