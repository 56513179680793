import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { LogService } from "../service/LogService";
import { useForm, Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { useTranslation } from './../components/hooks/Translate';
import {SupportDialogs} from "../components/support/SupportDialog";


const Logs = () => {
    const t = useTranslation();
    let emptyData = {
        id: "",
        tenantId: "",
        level: "",
        exceptionMessage: "",
        timeStamp: "",
        processTime: "",
        value: "",
        user: "",
        ipAddress: "",
        userAgent: "",
        exception: "",
        type: "",
    };

   
    const [datas, setDatas] = useState(null);

    const [dataDialog, setDataDialog] = useState(false);
    const [deleteDatasDialog, setDeleteDatasDialog] = useState(false);
    const [data, setData] = useState(emptyData);
    const [selectedDatas, setSelectedDatas] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState(null);
    const toast = useRef(null);
    const [supportDialog, setSupportDialog] = useState();
    const dt = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({ emptyData });

    const logService = new LogService();
    var query = `query {
        logList {
          success
          message
          data {
            exceptionMessage
            id
            ipAddress
            level
            processTime
            tenantId
            timeStamp
            user
            userAgent
            value
            type
          }
        }  
      }`;
    useEffect(() => {
        // logService.getList().then((data) => setDatas(data));
        logService.getGraphQLList(query).then((data) => setDatas(data.logList.data));        
        initFilters();
    },[]);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            tenantId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            value: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            level: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            timeStamp: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            exception: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            exceptionMessage: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            user: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilter("");
    };

    const hideDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setDataDialog(false);
    };

    const hideDeleteDatasDialog = () => {
        setDeleteDatasDialog(false);
    };

    const onSubmit = (val) => {
        Object.assign(data, val);
        if (data.id) {
            logService.update(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary:  t.translate("Updated"), detail: "Created", life: 3000 });
                    logService.getList().then((data) => setDatas(data));
                    setSelectedDatas(null);
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        } else {
            logService.create(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Added"), detail: "Created", life: 3000 });
                    logService.getList().then((data) => setDatas(data));
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        }

        setDataDialog(false);
        reset();
        setData(emptyData);
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };
    const editData = (data) => {
        let p = data[0];

        setValue("value", p.value);
        setValue("level", p.level);
        setValue("timeStamp", p.timeStamp);
        setValue("exceptionMessage", p.exceptionMessage);
        setValue("ipAddress", p.ipAddress);
        setValue("userAgent", p.userAgent);
        setValue("processTime", p.processTime);

        setData({ ...p });
        setDataDialog(true);
    };
  
    const hideSupportDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setSupportDialog(false);
    };
    const supportsDialog = () => {
        setSupportDialog(true);
    };
    const deleteSelectedDatas = () => {
        let _datas = datas.filter((val) => !selectedDatas.includes(val));

        selectedDatas.forEach((element) => {
            logService.delete(element.id).then((data) => {
                if (data !== "success") {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        });
        setDatas(_datas);
        setDeleteDatasDialog(false);
        setSelectedDatas(null);
        toast.current.show({ severity: "success", summary: t.translate("Deleted"), detail: `${_datas.length} items deleted`, life: 3000 });
    };

    const editSelectedData = () => {
        editData(selectedDatas);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button icon="pi pi-history" className="p-button-help mr-2" onClick={editSelectedData} disabled={!selectedDatas || !selectedDatas.length || selectedDatas.length > 1} />                                 
                </div>                
                      
                    
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
               <Button icon="pi pi-comments" className="p-button-danger" onClick={() => supportsDialog()}  tooltip={t.translate("Support")} tooltipOptions={{ position: "top" }} />   
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-datas-center">
            <h5 className="m-0">{t.translate("Logs")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t.translate("Search")} />
            </span>
        </div>
    );

    const deleteDialogFooter = (
        <>
            <Button label={t.translate("No")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDatasDialog} />
            <Button label={t.translate("Yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDatas} />
        </>
    );
    const timeStampBodyTemplate = (rowData) => {
        return (
            <p>
                {new Date(rowData.timeStamp).toLocaleDateString("tr-TR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                })}
            </p>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {<Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>}

                    <DataTable
                        ref={dt}
                        value={datas}
                        selection={selectedDatas}
                        filters={filters}
                        onSelectionChange={(e) => setSelectedDatas(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
                        globalFilter={globalFilter}
                        emptyMessage={t.translate("NoRecordsFound")}
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="id" header={t.translate("Id")} filter sortable></Column>
                        {/* <Column field="tenantId" header="Tenant" filter sortable></Column> */}
                        <Column field="processTime" header={t.translate("processTime")} filter sortable></Column>
                        <Column field="timeStamp" header={t.translate("date")} filter sortable body={timeStampBodyTemplate}></Column>
                         {/* <Column field="exceptionMessage" header="Exception" filter sortable></Column> */}
                        <Column field="level" header={t.translate("Level")} filter sortable></Column>
                        <Column field="user" header={t.translate("user")} filter sortable></Column>
                        <Column field="ipAddress" header={t.translate("IP")} filter sortable></Column>
                        {/* <Column field="value" header="Data" filter sortable></Column> */}
                        {/* <Column field="userAgent" header="UserAgent" filter sortable></Column> */}

                        <Column field="type" header={t.translate("type")} filter sortable></Column>
                    </DataTable>

                    <Dialog visible={dataDialog} style={{ width: "450px" }} header="Log" modal className="p-fluid" onHide={hideDialog}>
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="userAgent" control={control} render={({ field, fieldState }) => <InputTextarea disabled id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} rows={5} cols={30} autoResize />} />
                                    <label htmlFor="userAgent" className={classNames({ "p-error": !!errors.userAgent })}>
                                    {t.translate("userAgent")}
                                    </label>
                                </span>
                                {getFormErrorMessage("userAgent")}
                            </div>
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller
                                        name="value"
                                        control={control}
                                        rules={{ required: "MessageTemplate is required." }}
                                        render={({ field, fieldState }) => <InputTextarea disabled id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} rows={5} cols={30} autoResize />}
                                    />
                                    <label htmlFor="value" className={classNames({ "p-error": !!errors.value })}>
                                    {t.translate("Data")} 
                                    </label>
                                </span>
                                {getFormErrorMessage("value")}
                            </div>
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="level" control={control} rules={{ required: "Level is required." }} render={({ field, fieldState }) => <InputText disabled id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                    <label htmlFor="level" className={classNames({ "p-error": !!errors.level })}>
                                    {t.translate("Level")} 
                                    </label>
                                </span>
                                {getFormErrorMessage("level")}
                            </div>{" "}
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller
                                        name="timeStamp"
                                        control={control}
                                        rules={{ required: "timeStamp is required." }}
                                        render={({ field, fieldState }) => <Calendar disabled id={field.name} dateFormat="dd.mm.yy" value={new Date(data.timeStamp)} className={classNames({ "p-invalid": fieldState.invalid })} showTime showSeconds />}
                                    />
                                    <label htmlFor="timeStamp" className={classNames({ "p-error": !!errors.timeStamp })}>
                                    {t.translate("timeStamp")}  
                                    </label>
                                </span>
                                {getFormErrorMessage("timeStamp")}
                            </div>
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="exceptionMessage" control={control} render={({ field, fieldState }) => <InputTextarea disabled id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} rows={5} cols={30} autoResize />} />
                                    <label htmlFor="exceptionMessage" className={classNames({ "p-error": !!errors.exceptionMessage })}>
                                    {t.translate("exception")}  
                                    </label>
                                </span>
                                {getFormErrorMessage("exceptionMessage")}
                            </div>

                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="processTime" control={control} rules={{ required: "processTime is required." }} render={({ field, fieldState }) => <InputText disabled id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                    <label htmlFor="processTime" className={classNames({ "p-error": !!errors.processTime })}>
                                    {t.translate("processTime")}
                                    </label>
                                </span>
                                {getFormErrorMessage("processTime")}
                            </div>{" "}
                            <div className="grid">
                                {/* <div className="col-6">
                                    <Button type="submit" label={t.translate("Save")} />
                                </div> 
                                <div className="col-6">
                                    <Button label={t.translate("Cancel")} className="p-button-danger" onClick={(e) => hideDialog(e)} />
                                </div>*/}
                            </div>
                        </form>
                    </Dialog>

                    <Dialog visible={deleteDatasDialog} style={{ width: "450px" }} header={t.translate("Confirm")} modal footer={deleteDialogFooter} onHide={hideDeleteDatasDialog}>
                        <div className="flex align-datas-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {data && <span>{t.translate("DeleteConfirm")}</span>}
                        </div>
                    </Dialog>
                    <SupportDialogs visible={supportDialog}  hide={hideSupportDialog}></SupportDialogs>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Logs, comparisonFn);
