import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {CheckTokenExpire} from "./Token";



const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = JSON.parse(sessionStorage.getItem("token") || "{}");
    if(Object.keys(token).length !== 0){
        CheckTokenExpire(token);
        config.headers!["Authorization"] = `Bearer ${token}`;
    }
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

export const  setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};
