import { language } from "../models/language";
import GenericService from "./GenericService";
import api from "./Api";
export class LanguageService extends GenericService<language> {
    constructor() {
        super("Languages");
        
    }
    getlookupwithcode = async () => {  
        return await api.get<language[]>(`/Languages/codes`).then((res) => {
            return res.data;
        });
    };

    getlookups = async () => {  
        return await api.get<language[]>(`/Languages/lookups`).then((res) => {
            return res.data;
        });
    };
}
