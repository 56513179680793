import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { ReportService } from "../service/ReportService";

const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

const groupBy = (data, key) => {
    return data.reduce(function (acc, item) {
        (acc[item[key]] = acc[item[key]] || []).push(item);
        return acc;
    }, {});
};

const Report = () => {
    const [barOptions, setBarOptions] = useState(null);
    const [roomCountData, setRoomCountData] = useState();

    const reportService = new ReportService();
    useEffect(() => {
        reportService.getRoomCountReport("1").then((data) => setRoomCountData(data));
    }, []);

    const roomCountHandler = () => {
        if (roomCountData) {
            const countData = {
                labels: roomCountData.map((x) => x.roomName),
                datasets: [
                    {
                        label: "Boş",
                        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                        borderColor: ["rgb(255, 99, 132)"],
                        borderWidth: 1,
                        data: roomCountData?.map((x) => x.emptyCount),
                    },
                    {
                        label: "Dolu",
                        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
                        borderColor: ["rgb(75, 192, 192)"],
                        borderWidth: 1,
                        data: roomCountData?.map((x) => x.fullCount),
                    },
                ],
            };

            return countData;
        }
        return null;
    };

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#000000",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#000000",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#000000",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        const barOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || "";

                            if (label) {
                                label += ": ";
                            }
                            if (context.parsed.y !== null) {
                                label += context.parsed.y + " kişilik";
                            }
                            return label;
                        },
                    },
                },
                legend: {
                    labels: {
                        color: "#000000",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#000000",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#000000",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };
        setBarOptions(barOptions);
    };

    useEffect(() => {
        applyDarkTheme();
    }, []);

    return (
        <div className="grid p-fluid">
            <div className="col-12 lg:col-6">
                <div className="card">
                    <h5>Kişilere Göre Oda Sayısı</h5>
                    <Chart type="bar" data={roomCountHandler()} options={barOptions} />
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Report, comparisonFn);
