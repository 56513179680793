import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import Dashboard from "./components/Dashboard";
import Documentation from "./components/Documentation";
import FormLayoutDemo from "./components/FormLayoutDemo";
import Login from "./components/Login";
import Company from "./pages/Company";
import Organization from "./pages/Organization";
import Language from "./pages/Language";
import User from "./pages/User";
import Support from "./pages/Support";
import Priority from "./pages/Priority";
import SubjectType from "./pages/SubjectType";
import Group from "./pages/Group";
import GroupClaim from "./pages/GroupClaim";
import UserClaim from "./pages/UserClaim";
import UserGroup from "./pages/UserGroup";
import UserOperationClaim from "./pages/UserOperationClaim";
import OperationClaim from "./pages/OperationClaim";
import Translate from "./pages/Translate";
import { useTranslation } from "./components/hooks/Translate";
import Log from "./pages/Log";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import { AuthProvider, AuthRoute } from "./Context";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import { CheckRefreshToken } from "./service/Token";
import Profile from "./pages/Profile";
import Report from "./pages/Report";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    PrimeReact.ripple = true;
    
    const  t = useTranslation();
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        CheckRefreshToken();
    });

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };    

    const getTranslation= (val) => {
        if(t)
            return t.translate(val)
        else
            return val
    }

    const menu = [        
        {
            label: 'Menu', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: getTranslation('Users'), icon: 'pi pi-fw pi-users',
                    items: [
                        { label: getTranslation("Participants"), icon: "pi pi-fw pi-id-card", to: "/participant", claims:"GetParticipantsQuery" },                     
                        { label: getTranslation("ParticipantRooms"), icon: "pi pi-fw pi-check-square", to: "/booking" , claims:"GetBookingsQuery" },
                        {label: getTranslation("Reports"), icon: "pi pi-fw pi-home", to: "/report", claims:"GetReportQuery"
                        },
                    ],
                },
                {
                    label: getTranslation('Management'), icon: 'pi pi-fw pi-shield',
                    items: [
                        { label: getTranslation("Firms"), icon: "pi pi-fw pi-sitemap", to: "/company" , claims:"GetCompaniesQuery" },
                        { label: getTranslation("Users"), icon: "pi pi-fw pi-prime", to: "/userDto" , claims:"GetUsersQuery" },
                        { label: getTranslation("Organizations"), icon: "pi pi-fw pi-briefcase", to: "/organization" , claims:"GetOrganizationsQuery" },
                        { label: getTranslation("Groups"), icon: "pi pi-fw pi-users", to: "/group" , claims:"GetGroupsQuery" },
                        { label: getTranslation("OperationClaims"), icon: "pi pi-shield", to: "/operationClaim" , claims:"GetOperationClaimsQuery" },
                        { label: getTranslation("Languages"), icon: "pi pi-fw pi-globe", to: "/language" , claims:"GetLanguagesQuery" },
                        { label: getTranslation("Translates"), icon: "pi pi-fw pi-flag", to: "/translate" , claims:"GetTranslatesQuery" },
                        { label: getTranslation("Logs"), icon: "pi pi-fw pi-database", to: "/log", claims:"GetLogDtoQuery" },
                        { label: getTranslation("Supports"), icon: "pi pi-fw pi-envelope", to: "/support" , claims:"GetSupportsQuery" },
                        
                    ],
                },
                {
                    label: getTranslation('Project Definitions'), icon: 'pi pi-fw pi-th-large',
                    items: [
                        { label: getTranslation("SupportPriorities"), icon: "pi pi-fw pi-bolt", to: "/priority" , claims:"GetPrioritiesQuery" },
                        { label: getTranslation("SupportSubjectTypes"), icon: "pi pi-fw pi-bolt", to: "/subjecttype" , claims:"GetSubjectTypesQuery" },
                    ],
                },
            ]
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const defaultContainer = () => (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />                
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route exact path="/" render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/documentation" component={Documentation} />                    
                    <Route path="/userDto" component={User} />
                    <Route path="/support" component={Support} />
                    <Route path="/priority" component={Priority} />
                    <Route path="/subjecttype" component={SubjectType} />
                    <Route path="/company" component={Company} />                    
                    <Route path="/organization" component={Organization} />                    
                    <Route path="/operationClaim" component={OperationClaim} />
                    <Route path="/group" component={Group} />
                    <Route path="/groupClaim" component={GroupClaim} />
                    <Route path="/userClaim" component={UserClaim} />
                    <Route path="/userGroup" component={UserGroup} />
                    <Route path="/userOperationClaim" component={UserOperationClaim} />
                    <Route path="/language" component={Language} />
                    <Route path="/translate" component={Translate} />
                    <Route path="/log" component={Log} />
                    <Route path="/report" component={Report} />
                    <Route path="/profile" component={Profile} />  
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );

    return (
        <AuthProvider>
            <AuthRoute component={defaultContainer} />
            <Route exact path="/login" component={Login} />
        </AuthProvider>
    );
};

export default App;
