const ObjectToArray = (obj, objLabel, objVal, objLabel2) => {
    let dropdownArray = [];
    let label = getFields(obj, objLabel);
    let val = getFields(obj, objVal);
    if(objLabel2 !== undefined){
        let label2 = getFields(obj, objLabel2);
        for (let index = 0; index < label.length; index++) {
            dropdownArray.push({ value: val[index], label: label[index] + ' ' + label2[index] });
        }
    }else{
        for (let index = 0; index < label.length; index++) {
            dropdownArray.push({ value: val[index], label: label[index] });
        }
    }
    return dropdownArray;
};

const CheckObjectLabel = (obj, objLabel) => {
    let label = getFields(obj, objLabel);
    if (label[0] === undefined) return false;
    else return true;
};

const getFields = (input, field) => {
    var output = [];
    for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
};
export { ObjectToArray, CheckObjectLabel };
