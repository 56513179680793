import { UserClaim } from "../models/userClaim";
import api from "./Api";
import GenericService from "./GenericService";

const entitySlug = "User-Claims";
export class UserClaimService extends GenericService<UserClaim> {
    constructor() {
        super(entitySlug);
    }

    getOperationClaimByUserId = async (id: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/user/", { params: { id: id } }).then((res) => {
            return res.data;
        });
    };

    getByUserId = async (id: number): Promise<any> => {
        return await api.get<any>(entitySlug + "/users/"+id).then((res) => {
            return res.data;
        });
    };
}
