import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import AuthService from "./service/AuthService";
import { getUserId } from "./service/Token";
import { UserDtoService } from "./service/UserDtoService";
import { AuthContext, useAuthContext } from "./Context";
import { useTranslation } from "./components/hooks/Translate";

export const AppTopbar = (props) => {
    const [userName, setUserName] = useState(null);
    const user = useAuthContext();

    const logout = () => {
        const authService = new AuthService();
        authService.logout();
    };
    const userdtoService = new UserDtoService();
    useEffect(() => {
        const userId = getUserId(sessionStorage.getItem("token"));
        userdtoService.getbyid(userId).then((data) => {
            setUserName(data.fullName)
        });
    }, []);

    const renderContactName = () => {
        return (
            <AuthContext.Consumer>
                {({ user }) => {
                    if(!user || !user.fullName ){
                        // return t.translate("Hoş geldiniz")
                        return "Hoş geldiniz"
                    }else{
                        return user.fullName
                    }
                }}
            </AuthContext.Consumer>
        );
    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === "light" ? "assets/layout/images/logo-dark.svg" : "assets/layout/images/logo-white.svg"} alt="logo" />
                <span>Snappyup</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li> */}
                <li className="layout-topbar-username">{renderContactName()}</li>
                <li>
                    <Link to="/profile" className="layout-topbar-logo">
                        <button className="p-link layout-topbar-button">
                            <i className="pi pi-user" />
                            <span>Profile</span>
                        </button>
                    </Link>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={() => logout()}>
                        <i className="pi pi-sign-out" />
                        <span>Quit</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
