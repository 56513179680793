import axios from "axios";
import { setupInterceptorsTo } from "./Interceptors";
const apiGraphQL = setupInterceptorsTo(
  axios.create({
    baseURL:  process.env.REACT_APP_GRAPHQLAPI_URL,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default apiGraphQL;