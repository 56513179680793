import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { exportPdf, exportExcel } from "../components/Export";
import { UserGroupService } from "../service/UserGroupService";
import { useForm, Controller } from "react-hook-form";
import { GroupService } from "../service/GroupService";
import { UserDtoService } from "../service/UserDtoService";
import { useTranslation } from "../components/hooks/Translate";
import { Dropdown } from "primereact/dropdown";
import { ObjectToArray } from "../components/ObjectToArrayConvert";

const UserGroups = () => {
    const t = useTranslation();
    let emptyData = {
        groupId: "",
        userId: "",
    };

    const [datas, setDatas] = useState(null);
    const [groups, setGroups] = useState(null);
    const [users, setUsers] = useState(null);

    const [filteredData, setFilteredData] = useState(null);
    const [dataDialog, setDataDialog] = useState(false);
    const [deleteDatasDialog, setDeleteDatasDialog] = useState(false);
    const [data, setData] = useState(emptyData);
    const [selectedDatas, setSelectedDatas] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({ emptyData });

    const usergroupService = new UserGroupService();
    const groupService = new GroupService();
    const userService = new UserDtoService();

    useEffect(() => {
        usergroupService.getList().then((data) => setDatas(data));
        groupService.getList().then((data) => setGroups(ObjectToArray(data, "groupName", "id")));
        userService.getList().then((data) => setUsers(ObjectToArray(data, "fullName", "id")));

        initFilters();
    }, []);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            groupId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            userId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilter("");
    };

    const openNew = () => {
        reset();
        setData(emptyData);
        setDataDialog(true);
    };

    const hideDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setDataDialog(false);
    };

    const hideDeleteDatasDialog = () => {
        setDeleteDatasDialog(false);
    };

    const onSubmit = (val) => {
        Object.assign(data, val);
        if (data.id) {
            usergroupService.update(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Updated"), detail: "Created", life: 3000 });
                    usergroupService.getList().then((data) => setDatas(data));
                    setSelectedDatas(null);
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        } else {
            usergroupService.create(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Added"), detail: "Created", life: 3000 });
                    usergroupService.getList().then((data) => setDatas(data));
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        }

        setDataDialog(false);
        reset();
        setData(emptyData);
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };
    const editData = (data) => {
        let p = data[0];

        setValue("groupId", p.groupId);
        setValue("userId", p.userId);

        setData({ ...p });
        setDataDialog(true);
    };

    //#region  Export
    const columns = [
        { field: "groupId", header: t.translate("groupId") },
        { field: "userId", header: t.translate("userId") },
    ];

    const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportPdff = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportPdf(exportColumns, exportData, "Data");
    };

    const exportExcell = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportExcel(exportData, "Data");
    };
    //#endregion

    const confirmDeleteSelected = () => {
        setDeleteDatasDialog(true);
    };

    const deleteSelectedDatas = () => {
        let _datas = datas.filter((val) => !selectedDatas.includes(val));

        selectedDatas.forEach((element) => {
            usergroupService.delete(element.id).then((data) => {
                if (data !== "success") {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        });
        setDatas(_datas);
        setDeleteDatasDialog(false);
        setSelectedDatas(null);
        toast.current.show({ severity: "success", summary: t.translate("Deleted"), detail: `${_datas.length} items deleted`, life: 3000 });
    };

    const editSelectedData = () => {
        editData(selectedDatas);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button icon="pi pi-plus" className="p-button-primary mr-2" onClick={openNew} />
                    <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedDatas || !selectedDatas.length} />
                    <Button icon="pi pi-pencil" className="p-button-success" onClick={editSelectedData} disabled={!selectedDatas || !selectedDatas.length || selectedDatas.length > 1} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={exportCSV} className="mr-2" data-pr-tooltip="CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcell} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdff} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-datas-center">
            <h5 className="m-0">{t.translate("UserGroups")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t.translate("Search")} />
            </span>
        </div>
    );

    const deleteDialogFooter = (
        <>
            <Button label={t.translate("No")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDatasDialog} />
            <Button label={t.translate("Yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDatas} />
        </>
    );
    const groupBodyTemplate = (rowData) => {
        let obj = groups?.find((o) => o.value === rowData.groupId);
        return <p>{obj?.label}</p>;
    };
    const userBodyTemplate = (rowData) => {
        let obj = users?.find((o) => o.value === rowData.userId);
        return <p>{obj?.label}</p>;
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={datas}
                        selection={selectedDatas}
                        filters={filters}
                        onSelectionChange={(e) => setSelectedDatas(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
                        globalFilter={globalFilter}
                        emptyMessage={t.translate("NoRecordsFound")}
                        header={header}
                        responsiveLayout="scroll"
                        onValueChange={(filteredData) => setFilteredData(filteredData)}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="groupId" header={t.translate("groupId")} filter sortable body={groupBodyTemplate}></Column>
                        <Column field="userId" header={t.translate("userId")} filter sortable body={userBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={dataDialog} style={{ width: "450px" }} header="UserGroup" modal className="p-fluid" onHide={hideDialog}>
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="groupId" control={control} rules={{ required: "GroupId is required." }} render={({ field, fieldState }) => <Dropdown options={groups} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                    <label htmlFor="groupId" className={classNames({ "p-error": !!errors.groupId })}>
                                    {t.translate("groupId")}
                                    </label>
                                </span>
                                {getFormErrorMessage("groupId")}
                            </div>{" "}
                            <div className="field mt-4">
                                <span className="p-float-label p-input-icon-right">
                                    <Controller name="userId" control={control} rules={{ required: "UserId is required." }} render={({ field, fieldState }) => <Dropdown options={users} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                    <label htmlFor="userId" className={classNames({ "p-error": !!errors.userId })}>
                                    {t.translate("userId")}
                                    </label>
                                </span>
                                {getFormErrorMessage("userId")}
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <Button type="submit" label={t.translate("Save")} />
                                </div>
                                <div className="col-6">
                                    <Button label={t.translate("Cancel")} className="p-button-danger" onClick={(e) => hideDialog(e)} />
                                </div>
                            </div>
                        </form>
                    </Dialog>

                    <Dialog visible={deleteDatasDialog} style={{ width: "450px" }} header={t.translate("Confirm")} modal footer={deleteDialogFooter} onHide={hideDeleteDatasDialog}>
                        <div className="flex align-datas-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {data && <span>{t.translate("DeleteConfirm")}</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserGroups, comparisonFn);
