import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { exportPdf, exportExcel } from "../components/Export";
import { OrganizationService } from "../service/OrganizationService";
import { CompanyService } from "../service/CompanyService";
import { useForm, Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { ObjectToArray, CheckObjectLabel } from "../components/ObjectToArrayConvert";
import { Visible } from "../components/hooks/Visible";
import { useTranslation } from "../components/hooks/Translate";

const Organizations = () => {
    const t = useTranslation();
    let emptyData = {
        id: "",
        tenantId: "",
        organizationName: "",
        startDate: "",
        endDate: "",
        address: "",
        webSite: "",
    };

    const [datas, setDatas] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [dataDialog, setDataDialog] = useState(false);
    const [deleteDatasDialog, setDeleteDatasDialog] = useState(false);
    const [data, setData] = useState(emptyData);
    const [selectedDatas, setSelectedDatas] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm({ emptyData });

    const organizationService = new OrganizationService();
    const companiesService = new CompanyService();
    useEffect(() => {
        organizationService.getList().then((data) => setDatas(data));
        companiesService.getList().then((data) => (CheckObjectLabel(data, "name") ? setCompanies(ObjectToArray(data, "name", "id")) : toastError("companiesService label not found")));
        initFilters();
    },[]);

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            tenantId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            organizationName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            startDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            endDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            address: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            webSite: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilter("");
    };

    const openNew = () => {
        reset();
        setData(emptyData);
        setDataDialog(true);
    };

    const hideDialog = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setDataDialog(false);
    };

    const hideDeleteDatasDialog = () => {
        setDeleteDatasDialog(false);
    };

    const onSubmit = (val) => {
        Object.assign(data, val);

        if (data.id) {
            organizationService.update(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary:  t.translate("Updated"), detail: "Created", life: 3000 });
                    organizationService.getList().then((data) => setDatas(data));
                    setSelectedDatas(null);
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        } else {
            organizationService.create(data).then((data) => {
                if (data === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Added"), detail: "Created", life: 3000 });
                    organizationService.getList().then((data) => setDatas(data));
                } else {
                    toastError(data);
                }
            });
        }

        setDataDialog(false);
        reset();
        setData(emptyData);
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };
    const editData = (data) => {
        let p = data[0];

        setValue("organizationName", p.organizationName);
        setValue("startDate", p.startDate);
        setValue("endDate", p.endDate);
        setValue("address", p.address);
        setValue("webSite", p.webSite);
        setData({ ...p });
        setDataDialog(true);
    };

    //#region  Export
    const columns = [
        { field: "id", header: t.translate("Id") },
        { field: "tenantId", header: t.translate("tenantId") },
        { field: "organizationName", header: t.translate("organizationName") },
        { field: "startDate", header: t.translate("startDate") },
        { field: "endDate", header: t.translate("endDate") },
        { field: "address", header: t.translate("address") },
        { field: "webSite", header: t.translate("webSite") },
    ];

    const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportPdff = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportPdf(exportColumns, exportData, "Data");
    };

    const exportExcell = () => {
        let exportData = filteredData=== null ? datas : filteredData;
        exportExcel(exportData, "Data");
    };
    //#endregion

    const toastError = (detail) => {
        toast.current.show({ severity: "error", summary: t.translate("Error"), detail: detail, life: 3000 });
    };
    const confirmDeleteSelected = () => {
        setDeleteDatasDialog(true);
    };

    const deleteSelectedDatas = () => {
        let _datas = datas.filter((val) => !selectedDatas.includes(val));

        selectedDatas.forEach((element) => {
            organizationService.delete(element.id).then((data) => {
                if (data !== "success") {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: data, life: 3000 });
                }
            });
        });
        setDatas(_datas);
        setDeleteDatasDialog(false);
        setSelectedDatas(null);
        toast.current.show({ severity: "success", summary: t.translate("Deleted"), detail: `${_datas.length} items deleted`, life: 3000 });
    };

    const editSelectedData = () => {
        editData(selectedDatas);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Visible roles={["CreateOrganizationCommand"]}>
                        <Button icon="pi pi-plus" className="p-button-primary mr-2" onClick={openNew} tooltip={t.translate("Add")} tooltipOptions={{ position: "top" }} />
                    </Visible>
                    <Visible roles={["DeleteOrganizationCommand"]}>
                        <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} tooltip={t.translate("Delete")} tooltipOptions={{ position: "top" }} disabled={!selectedDatas || !selectedDatas.length} />
                    </Visible>
                    <Visible roles={["UpdateOrganizationCommand"]}>
                        <Button icon="pi pi-pencil" className="p-button-success" onClick={editSelectedData} tooltip={t.translate("Update")} tooltipOptions={{ position: "top" }} disabled={!selectedDatas || !selectedDatas.length || selectedDatas.length > 1} />
                    </Visible>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={exportCSV} className="mr-2" data-pr-tooltip="CSV" tooltip="Csv Export" tooltipOptions={{ position: "top" }} />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcell} className="p-button-success mr-2" data-pr-tooltip="XLS" tooltip="Xls Export" tooltipOptions={{ position: "top" }} />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdff} className="p-button-warning mr-2" data-pr-tooltip="PDF" tooltip="Pdf Export" tooltipOptions={{ position: "top" }} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-datas-center">
            <h5 className="m-0">{t.translate("Organizations")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t.translate("Search")} />
            </span>
        </div>
    );

    const deleteDialogFooter = (
        <>
            <Button label={t.translate("No")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDatasDialog} />
            <Button label={t.translate("Yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDatas} />
        </>
    );

    const startDateBodyTemplate = (rowData) => {
        return <p>{new Date(rowData.startDate).toLocaleDateString()}</p>;
    };
    const endDateBodyTemplate = (rowData) => {
        return <p>{new Date(rowData.endDate).toLocaleDateString()}</p>;
    };
    const tenantBodyTemplate = (rowData) => {
        let obj = companies?.find((o) => o.value === rowData.tenantId);
        return <p>{obj?.label}</p>;
    };
    return (
        <Visible roles={["GetOrganizationsQuery"]}>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={datas}
                            selection={selectedDatas}
                            filters={filters}
                            onSelectionChange={(e) => setSelectedDatas(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
                            globalFilter={globalFilter}
                            emptyMessage={t.translate("NoRecordsFound")}
                            header={header}
                            responsiveLayout="scroll"
                            stripedRows
                            onValueChange={(filteredData) => setFilteredData(filteredData)}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                            <Column field="id" header={t.translate("Id")} filter sortable></Column>
                            <Column field="tenantId" header={t.translate("tenantId")} filter sortable body={tenantBodyTemplate}></Column>
                            <Column field="organizationName" header={t.translate("organizationName")} filter sortable></Column>
                            <Column field="startDate" header={t.translate("startDate")} filter sortable body={startDateBodyTemplate}></Column>
                            <Column field="endDate" header={t.translate("endDate")} filter sortable body={endDateBodyTemplate}></Column>
                            <Column field="address" header={t.translate("address")} filter sortable></Column>
                            <Column field="webSite" header={t.translate("webSite")} filter sortable></Column>
                        </DataTable>

                        <Dialog visible={dataDialog} style={{ width: "450px" }} header="Organizayon Tanımları" modal className="p-fluid" onHide={hideDialog}>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="organizationName" control={control} rules={{ required: "OrganizationName is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="organizationName" className={classNames({ "p-error": !!errors.organizationName })}>
                                        {t.translate("organizationName")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("organizationName")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            rules={{ required: "startDate is required." }}
                                            render={({ field, fieldState }) => <Calendar id={field.name} dateFormat="dd.mm.yy" value={new Date(data.startDate)} className={classNames({ "p-invalid": fieldState.invalid })} />}
                                        />
                                        <label htmlFor="startDate" className={classNames({ "p-error": !!errors.startDate })}>
                                        {t.translate("startDate")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("startDate")}
                                </div>{" "}
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller
                                            name="endDate"
                                            control={control}
                                            rules={{ required: "endDate is required." }}
                                            render={({ field, fieldState }) => <Calendar id={field.name} dateFormat="dd.mm.yy" value={new Date(data.endDate)} className={classNames({ "p-invalid": fieldState.invalid })} />}
                                        />
                                        <label htmlFor="endDate" className={classNames({ "p-error": !!errors.endDate })}>
                                        {t.translate("endDate")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("endDate")}
                                </div>
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="address" control={control} rules={{ required: "Address is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="address" className={classNames({ "p-error": !!errors.address })}>
                                        {t.translate("address")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("address")}
                                </div>
                                <div className="field mt-4">
                                    <span className="p-float-label p-input-icon-right">
                                        <Controller name="webSite" control={control} rules={{ required: "WebSite is required." }} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                        <label htmlFor="webSite" className={classNames({ "p-error": !!errors.webSite })}>
                                        {t.translate("webSite")}
                                        </label>
                                    </span>
                                    {getFormErrorMessage("webSite")}
                                </div>
                                <div className="grid">
                                    <div className="col-6">
                                        <Button type="submit" label={t.translate("Save")} />
                                    </div>
                                    <div className="col-6">
                                        <Button label={t.translate("Cancel")} className="p-button-danger" onClick={(e) => hideDialog(e)} />
                                    </div>
                                </div>
                            </form>
                        </Dialog>

                        <Dialog visible={deleteDatasDialog} style={{ width: "450px" }} header={t.translate("Confirm")} modal footer={deleteDialogFooter} onHide={hideDeleteDatasDialog}>
                            <div className="flex align-datas-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {data && <span>{t.translate("DeleteConfirm")}</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </Visible>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Organizations, comparisonFn);
