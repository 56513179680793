import React, { useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthService from "../service/AuthService";
import { Toast } from "primereact/toast";
import { useForm, Controller } from "react-hook-form";
import { Password } from "primereact/password";
import classNames from "classnames";
import { Divider } from "primereact/divider";
import { useTranslation } from './hooks/Translate';

export const ChangePassword = ({ visible, userId, hide }) => {
    const t = useTranslation();
    const defaultValues = {
        password: '',
        confirm_password: ''
    }
    const toast = useRef(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
    } = useForm({defaultValues});

    useEffect(() => {
        reset();
    }, []);

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const onSubmit = (val) => {        
        if (val.password != val.confirm_password) {
            toast.current.show({ severity: "error", summary: t.translate("Error"), detail: "Passwords not matched", life: 3000 });
        }else{
            if (userId.length > 0) {
                const authService = new AuthService();
                authService.changePassword(userId[0].userId || userId, val.password).then((res) => {
                    if (res === "Updated") {
                        toast.current.show({ severity: "success", summary:  t.translate("Updated"), detail: "Created", life: 3000 });
                       hide()
                    } else {
                        toast.current.show({ severity: "error", summary: t.translate("Error"), detail: res, life: 3000 });
                    }
                });
            }
        }
       
    };

    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Password Credentials</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>At least one special character</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Parola Değiştir" visible={visible} style={{ maxWidth: "60vw" }} modal onHide={hide}>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field mb-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: "Password is required.", pattern: { value: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i, message: "Password credentials missing" } }}
                                render={({ field, fieldState, ref }) => <Password id={field.name} {...field} feedback={true} footer={footer} toggleMask className={classNames({ "p-invalid": fieldState.invalid })} />}
                            />
                            <label htmlFor="password" className={classNames({ "p-error": errors.password })}>
                                Password
                            </label>
                        </span>
                        {getFormErrorMessage("password")}
                    </div>
                    <div className="field mb-4">
                        <span className="p-float-label p-input-icon-right">
                            <Controller
                                name="confirm_password"
                                defaultValue = {''}
                                control={control}
                                rules={{ required: "Confirm Password is required." }}
                                render={({ field, fieldState }) => <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ "p-invalid": fieldState.invalid })} />}
                            />
                            <label htmlFor="confirm_password" className={classNames({ "p-error": errors.confirm_password })}>
                                Password Tekrarı
                            </label>
                        </span>
                        {getFormErrorMessage("confirm_password")}
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <Button type="submit" label={t.translate("Save")}  />
                        </div>
                        <div className="col-6">
                            <Button label={t.translate("Cancel")} className="p-button-danger" onClick={hide} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
