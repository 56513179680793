import { GroupClaim } from "../models/groupClaim";
import api from "./Api";
import GenericService from "./GenericService";

let entitySlug = "Group-Claims";
export class GroupClaimService extends GenericService<GroupClaim> {
    constructor() {
        super(entitySlug);
    }

    getOperationClaimByUserId = async (id: string): Promise<any> => {
        return await api.get<any>(entitySlug + "/groups/" + id).then((res) => {
            return res.data;
        });
    };
}
