import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { UserClaimService } from "../../service/UserClaimService";
import { OperationClaimService } from "../../service/OperationClaimService";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useTranslation } from "../hooks/Translate"

export const UserClaims = ({ visible, userId, hide }) => {
    const t = useTranslation();
    const [claims, setClaims] = useState();
    const [selectedClaims, setSelectedClaims] = useState([]);
    const toast = useRef(null);
    const userClaimService = new UserClaimService();
    const operationClaimService = new OperationClaimService();

    useEffect(() => {
        if (userId!=null && userId.length > 0) {
            userClaimService.getByUserId(userId[0].userId || userId).then((data) => setSelectedClaims(data.map((d) => d.id.toString())));
            operationClaimService.getList().then((data) => setClaims(data.map((d) => ({ label: d.name, value: d.id.toString() }))));
        }
    }, [userId]);

    const addOrUpdateClaims = (userId) => {
       if (userId.length > 0) {
        if (userId[0]?.userId == 1) {
            toast.current.show({ severity: "error", summary: t.translate("Error"), detail: "Bu kullanıcının yetkisi değiştirilemez.", life: 3000 });
        }else{
            userClaimService.update({ userId: userId[0].userId, claimIds: selectedClaims }).then((res) => {
                if (res === "success") {
                    toast.current.show({ severity: "success", summary: t.translate("Added"), detail: "Created", life: 3000 });
                    hide()
                } else {
                    toast.current.show({ severity: "error", summary: t.translate("Error"), detail: res, life: 3000 });
                }
            });
        }
            
       }
    };

    const footer = (
        <div>
            <Button label={t.translate("Save")} icon="pi pi-check" onClick={() => addOrUpdateClaims(userId)} />
            <Button label={t.translate("No")} icon="pi pi-times" onClick={hide} />
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header="Kullanıcı Yetkileri" footer={footer} visible={visible} style={{ maxWidth: "60vw" }} modal onHide={hide}>
                <MultiSelect value={selectedClaims} options={claims} onChange={(e) => setSelectedClaims(e.value)} optionLabel="label" placeholder="Lütfen Yetki Seçiniz" filter display="chip" style={{ maxWidth: "50vw" }} maxSelectedLabels={3} />
            </Dialog>
        </div>
    );
};
