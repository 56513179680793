import { Translate } from "../models/translate";
import GenericService from "./GenericService";
import api from "./Api";
const entitySlug = "Translates";
export class TranslateService extends GenericService<Translate> {
    constructor() {
        super(entitySlug);
    }
    getTranslatesByLang = async (lang: string) => {     
        return await api.get<Translate[]>(entitySlug+"/languages/"+lang).then((res) => {
            return res.data;
        });
    };

    uploadFiles = async (langId: number, contents: any) => {
        //const config = { headers: { 'accept': 'application/json','Content-Type': 'multipart/form-data' } };
        const data = JSON.stringify({langId: langId, Contents: contents})
        return await api
            .post(entitySlug+"/add-translates-from-files", data)
            .then(() => {
                return "success";
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
