import { UserDto } from "../models/userDto";
import api from "./Api";
import GenericService from "./GenericService";
const entitySlug = "Users"
export class UserDtoService extends GenericService<UserDto> {
    constructor() {
        super(entitySlug);
    }

    getUserLookup = async () => {
        return await api.get<any>(entitySlug + "/lookups").then((res) => {
            return res.data;
        });
    };
}
 