import React, { useRef, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import AuthService from "../service/AuthService";

import { useAuthContext } from "../Context";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { LanguageService } from "../service/LanguageService";
import { Dropdown } from "primereact/dropdown";
import { ObjectToArray } from "../components/ObjectToArrayConvert";

function Login() {
    const auth = useAuthContext();
    const [loading, setLoading] = useState(false);
    
    const toast = useRef(null);
    const [langs, setLangs] = useState(null);
    const defaultValues = {
        email: "",
        password: "",
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ defaultValues });
    const langService = new LanguageService();
    
    useEffect(() => {
        langService.getlookupwithcode().then((data) => setLangs(ObjectToArray(data, "label", "id")));
    }, []);

    const onSubmit = (data) => {
        const authService = new AuthService();
        setLoading(true);
        authService.login(data.email, data.password).then((res) => {
            if (res?.success) {
                sessionStorage.setItem("langs", data.langId);
                auth.setAuthenticated(true);
            } else {
                showError(res);
            }
            setLoading(false);
        });
        reset();
    };
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const showError = (message) => {
        return toast.current.show({ severity: "error", summary: "Login", detail: message, life: 3000 });
    };

    return (
        <div className="grid grid-nogutter surface-section text-800">
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <Toast ref={toast} />
                <div className="surface-card p-6 shadow-2 border-round w-full lg:w-12">
                    <div className="text-center mb-5">
                        <img src="images/blocks/logos/hyper.svg" alt="hyper" height="50" className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">Snappyup</div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="field mb-4">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: "Email is required.", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email address. E.g. example@email.com" } }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ "p-invalid": fieldState.invalid })} />}
                                />
                                <label htmlFor="email" className={classNames({ "p-error": !!errors.email })}>
                                    Email*
                                </label>
                            </span>
                            {getFormErrorMessage("email")}
                        </div>
                        <div className="field mb-4">
                            <span className="p-float-label p-input-icon-right">
                                <Controller name="password" control={control} rules={{ required: "Password is required." }} render={({ field, fieldState }) => <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ "p-invalid": fieldState.invalid })} />} />
                                <label htmlFor="password" className={classNames({ "p-error": errors.password })}>
                                    Password*
                                </label>
                            </span>
                            {getFormErrorMessage("password")}
                        </div>
                        <div className="field mt-4">
                            <span className="p-float-label p-input-icon-right">
                                <Controller name="langId" control={control} rules={{ required: "LangId is required." }} render={({ field, fieldState }) => <Dropdown options={langs} onChange={(e) => field.onChange(e.value)} id={field.name} value={field.value} />} />
                                <label htmlFor="langId" className={classNames({ "p-error": !!errors.langId })}>
                                    Dil Seçimi
                                </label>
                            </span>
                            {getFormErrorMessage("langId")}
                        </div>
                        <div className="field-checkbox">
                            <Controller name="accept" control={control} render={({ field, fieldState }) => <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} />} />
                            <label htmlFor="accept">Remember me</label>
                        </div>

                        <Button type="submit" label="Signin" loading={loading} className="mt-2" />
                    </form>
                </div>
            </div>
            <div className="col-12 md:col-6 overflow-hidden">
                <img src="images/blocks/hero/hero-1.png" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }} />
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Login, comparisonFn);
