import React, { useContext, createContext, useState, useLayoutEffect } from "react";
import { Lookup } from "./service/UserService";
import { Redirect, Route, useLocation } from "react-router-dom";
import { UserClaimService } from "./service/UserClaimService";
import { getUserId } from "./service/Token";
import { UserDtoService } from "./service/UserDtoService";
export const AuthContext = createContext();

export function useAuthContext() {
    return useContext(AuthContext);
}

export const AuthProvider = (props) => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [claims, setClaims] = useState([]);
    const [isBusy, setBusy] = useState(true);
    const [user, setUser] = useState();
    const { children } = props;
   
    useLayoutEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token !== null) {
            if (!isAuthenticated) {
                setAuthenticated(true);
                Lookup().then((res) => {
                    const auth = res[0].isDisabled;
                    if (auth) {
                        setAuthenticated(false);
                    }
                });
            }

            if (claims.length === 0) {
                const userId = getUserId(token);
                const userCliams = new UserClaimService();
                userCliams.getByUserId(parseInt(userId)).then((data) => setClaims(data.map(item => item.label)));
            }
            if(!user){
                const userId = getUserId(token);
                const userdtoService = new UserDtoService();
                userdtoService.getbyid(userId).then((data) => {
                    setUser(data)
                });
            }
        } else {
            setAuthenticated(false);
        }
        setBusy(false);
    }, [isAuthenticated, claims, user]);
    return <AuthContext.Provider value={{ isAuthenticated, setAuthenticated, isBusy, claims, user }}> {children}</AuthContext.Provider>;
};

export const AuthRoute = ({ component: Component, ...rest }) => {
    let location = CurrentLocation();
    return (
        <AuthContext.Consumer>
            {({ isAuthenticated, isBusy }) => {
                if (!isBusy) {
                    let content = "";
                    if (isAuthenticated) {
                        content = <Route render={(props) => <Component {...props} />} {...rest} />;
                        if (location === "/login") {
                            content = <Redirect push to="/" />;
                        }
                    } else if (!isAuthenticated) {
                        content = <Redirect push to="/login" />;
                    }
                    return content;
                }
            }}
        </AuthContext.Consumer>
    );
};

const CurrentLocation = () => {
    const location = useLocation();
    return location.pathname;
};
